import { ASSESSMENT_RANGES } from 'src/constants';

const getDate = dateString => {
  const [month, day] = dateString.split('-');
  const year = new Date().getFullYear();

  return new Date(year, month - 1, day);
};
const assessmentRanges = ASSESSMENT_RANGES
  .split(',')
  .reduce((ranges, range, index) => {
    const position = index + 1;
    const [starDateString, endDateString] = range.split('|');
    const startDate = getDate(starDateString);
    const endDate = getDate(endDateString);
    ranges[position] = { startDate, endDate };

    return ranges;
  }, {});

const isCurrent = (startDate, endDate) => {
  const currentDate = new Date();
  return startDate <= currentDate && currentDate < endDate;
};

const isNext = startDate => {
  const currentDate = new Date();
  return startDate > currentDate;
};

const isClosed = endDate => {
  const currentDate = new Date();
  return currentDate > endDate;
};

export const periods = [
  {
    title: 'February',
    position: 1,
    get startDate() {
      return assessmentRanges[this.position].startDate;
    },
    get endDate() {
      return assessmentRanges[this.position].endDate;
    },
    get isCurrent() {
      return isCurrent(this.startDate, this.endDate);
    },
    get isNext() {
      return isNext(this.startDate);
    },
    get isClosed() {
      return isClosed(this.endDate);
    },
    hasNextStep(currentCycleSteps) {
      const nextPeriodSteps = currentCycleSteps.filter(step => step.position !== this.position);
      return nextPeriodSteps.length > 0;
    }
  },
  {
    title: 'August',
    position: 2,
    get startDate() {
      return assessmentRanges[this.position].startDate;
    },
    get endDate() {
      return assessmentRanges[this.position].endDate;
    },
    get isCurrent() {
      return isCurrent(this.startDate, this.endDate);
    },
    get isNext() {
      return isNext(this.startDate);
    },
    get isClosed() {
      return isClosed(this.endDate);
    },
    hasNextStep() {
      return false;
    }
  }
];
