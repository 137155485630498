import React from 'react';
import { generatePath } from 'react-router-dom';
import routes from 'src/next/navigation/routes';
import {
  CertificationStatus,
  certificationStatusOrderByStatusCycleOne
} from 'src/models/certification';
import CombinedJourneyElement from './CombinedJourneyElement';

const { BASELINE_APPROVED } = CertificationStatus;

const CERTIFICATION_FLOW_CHANGE_YEAR = 2024;
const stepTitleStrings = {
  1: 'February Assessment',
  2: 'August Assessment',
  default: 'Certification Assessment'
};

const JourneyBaselineDetails = ({ certification, baseline, accordionTitle, cycleYear, assessmentType }) => {
  const currentStatusOrder = certificationStatusOrderByStatusCycleOne.get(certification.status);
  const isBaselineApproved =
    currentStatusOrder >= certificationStatusOrderByStatusCycleOne.get(BASELINE_APPROVED);

  return (
    <CombinedJourneyElement
      title="Baseline Assessment"
      viewUrl={generatePath(routes.CERTIFICATION_STEP, {
        certificationId: certification.id,
        certificationStepId: baseline.id
      })}
      scoreTitle="Final Score:"
      score={baseline?.score.total}
      scoreDisabled={!isBaselineApproved}
      accordionTitle={accordionTitle}
      cycleYear={cycleYear}
      assessmentType={assessmentType}
      isBaseline={true}
      completed={isBaselineApproved}
      certificationId={certification.id}
      certificationStepId={baseline.id}
    />
  );
};

export default function JourneyCycleDetails({
  certification,
  evaluation,
  baseline,
  cycleYear,
  assessmentType,
  assessment,
  isBaseline,
  isAdmin,
  isPsTeamMember
}) {
  if (!isBaseline) {
    const total = assessment?.completion?.total ?? NaN;
    const completed = assessment?.completion?.completed ?? NaN;
    const currentStepPosition = assessment?.position;
    const isEvaluationApproved = completed === total;
    const viewUrl = assessment &&
      generatePath(routes.CERTIFICATION_STEP, {
        certificationId: certification.id,
        certificationStepId: assessment.id
      });

    const stepTitle = cycleYear < CERTIFICATION_FLOW_CHANGE_YEAR
      ? stepTitleStrings.default
      : stepTitleStrings[currentStepPosition];

    return (
      <CombinedJourneyElement
        title={stepTitle}
        viewUrl={viewUrl}
        disabled={!evaluation}
        assessmentType={assessmentType}
        scoreTitle="Final Score:"
        score={assessment?.score.total}
        scoreDisabled={!isEvaluationApproved}
        cycleYear={cycleYear}
        asset={assessment?.asset}
        certificationId={certification.id}
        certificationStepId={assessment.id}
        isAdmin={isAdmin}
        isPsTeamMember={isPsTeamMember}
      />
    );
  }

  if (baseline) {
    return (
      <JourneyBaselineDetails
        certification={certification}
        baseline={baseline}
        accordionTitle='Baseline'
        cycleYear={cycleYear}
        assessmentType={assessmentType}
      />
    );
  }

  return null;
}
