import styled from '@emotion/styled';
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from 'react';
import { isNumber } from 'lodash';
import { CERTIFIED_SCORE } from 'src/constants';
import BadgeIcon from './BadgeIcon';
import ReportContextMenu from './ReportContextMenu';

const scoreAndColorSelector = score => {
  const theme = useTheme();
  return {
    scoreColor: score >= CERTIFIED_SCORE ? theme.palette.secondary.main : theme.palette.tertiary.dark,
    displayScore: score
  };
};

const StyledReportTypography = styled(({ isBaseline, ...props }) => <Typography {...props} />)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  visibility: ${({ isBaseline }) => (isBaseline ? 'hidden' : 'visible')};
`;

const StyledReportArrowDown = styled(({ isBaseline, ...props }) => <ExpandMore {...props} />)`
  color: ${({ theme }) => theme.palette.primary.main};
  visibility: ${({ isBaseline }) => (isBaseline ? 'hidden' : 'visible')};
`;

const StyledGridItem = styled(Grid)`
  min-width: 85px;
`;

export default function CombinedJourneyElement({
  assessmentType,
  cycleYear,
  title,
  score,
  scoreTitle = 'Score',
  scoreDisabled = false,
  disabled,
  viewUrl,
  ViewButton = Button,
  isBaseline = false,
  asset,
  certificationId,
  certificationStepId,
  isAdmin,
  isPsTeamMember
}) {
  const { scoreColor, displayScore } = scoreAndColorSelector(score);
  const canManageReports = isAdmin || isPsTeamMember;

  return (
    <Box p={2.5}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <StyledGridItem item>
          <Typography width={165} fontSize={16} fontWeight={400} color={'#757575'} minWidth={185}>
            {title}
          </Typography>
        </StyledGridItem>
        {isNumber(score) && !scoreDisabled && (
          <>
            <StyledGridItem item>
              <Box display="flex" flexDirection="column" alignItems="center" minWidth={80}>
                <Box>
                  <Typography variant="microtext1">{scoreTitle}</Typography>
                </Box>
                <Box fontWeight="600" fontSize={20} color={scoreColor}>{displayScore}</Box>
              </Box>
            </StyledGridItem>
            <StyledGridItem item>
              <Box display="flex">
                <BadgeIcon
                  assessmentType={assessmentType}
                  score={score}
                  year={parseInt(cycleYear)}
                  width={51}
                  height={51}
                  isBaseline={isBaseline}
                />
              </Box>
            </StyledGridItem>
          </>
        )}
        <StyledGridItem item>
          {!isBaseline && (canManageReports || asset) && (
            <Box display="flex" alignItems="center">
              <StyledReportTypography
                variant='body2'
                isBaseline={isBaseline}
                style={{ display: 'inline-flex', alignItems: 'center' }}
              >
                Report
              </StyledReportTypography>
              <ReportContextMenu
                certificationId={certificationId}
                certificationStepId={certificationStepId}
                asset={asset}
                canManageReports={canManageReports}
                trigger={
                  <Box display="flex" alignItems="center">
                    <StyledReportArrowDown style={{ cursor: 'pointer', marginLeft: 2 }} />
                  </Box>
                }
              />
            </Box>
          )}
        </StyledGridItem>
        <StyledGridItem item>
          <StyledReportTypography variant='body2' isBaseline={isBaseline}>
            {/* Hide until Reporting functionality is implemented. */}
            {/* <u>Report</u> */}
          </StyledReportTypography>
        </StyledGridItem>
        <StyledGridItem item>
          <ViewButton
            to={viewUrl}
            disabled={disabled}
            onClick={() => window.scrollTo({ top: 0, behavior: 'auto' })}
            color="secondary"
            variant="contained"
            size='medium'
          >
            View
          </ViewButton>
        </StyledGridItem>
      </Grid>
    </Box>
  );
}
